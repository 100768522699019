import {useParams} from "react-router-dom";
import React, {useEffect} from "react";
import CasesService from "../../../services/cases.service";
import {Col, Modal, Radio, Row, Space, Tabs} from "antd";
import ReactPlayer from "react-player";
import '../cases.css';

export function CaseDetails() {
    const params = useParams();
    const [caseDetails, setCaseDetails] = React.useState({});
    const [tabs, setTabs] = React.useState([]);
    const [openTab, setOpenTab] = React.useState('description');
    const [showModal, setShowModal] = React.useState(false);
    const [modalImg, setModalImg] = React.useState('');
    const [quizAttempted, setQuizAttempted] = React.useState(false);

    useEffect(() => {
        if (quizAttempted) {
            changeTab("explanation");
        }
    }, [quizAttempted]);

    const changeTab = function (key) {
        if(key === "explanation" && !quizAttempted){
            return false;
        }
        setOpenTab(key);
    }

    useEffect(() => {
        CasesService.caseById(params.id).then(response => {
            if (response.success) {
                setCaseDetails(response.data);
                setTabs([{
                    label: `Description`,
                    key: 'description',
                    children: <CaseDescription caseDetails={response.data} changeTab={changeTab} openImage={openImage}/>
                }, {
                    label: "Q&A",
                    key: 'questions',
                    children: <CaseTest caseDetails={response.data} changeTab={changeTab} setQuizAttempted={setQuizAttempted} openImage={openImage}/>,
                }, {
                    label: 'Capsule',
                    key: 'explanation',
                    children: <CaseExplanation caseDetails={response.data} changeTab={changeTab} openImage={openImage}/>,
                }]);
            }
        }).catch(error => {
            console.log(error);
        })
    }, []);

    const openImage = (img) => {
        setModalImg(img);
        setShowModal(true);
    }

    return (<div className={"container"}>
        {caseDetails && <Row>
            <Col xl={18} lg={16} md={24}>
                <h2 className={"case-title"}>{caseDetails.case_name}</h2>
                <p className={"case-category"}>Medicine > CVS</p>
                <div className={"mt-4"}>
                    <ReactPlayer url={caseDetails.case_files} controls={true} width={"100%"} height={"auto"}/>
                </div>
                <Tabs rootClassName={"mt-3 description-tabs"} type={"card"} activeKey={openTab} items={tabs}
                      onTabClick={(key) => changeTab(key)}>
                </Tabs>
            </Col>
            <Col xl={6} lg={8} md={24}>
            </Col>
        </Row>}

        <Modal
            title=""
            centered
            footer={null}
            onCancel={() => setShowModal(false)}
            open={showModal}
            width={"80%"}>
            <img src={modalImg} className={"w-100"}/>
        </Modal>
    </div>);
}

export function CaseDescription({caseDetails, changeTab, openImage}) {

    return (<div>
        {caseDetails.case_description.map(cd => (<div key={cd.id}>
            <h2 className={"app-section-title mt-0"}>{cd.title}</h2>
            <div dangerouslySetInnerHTML={{__html: cd.description}}></div>
        </div>))}
        <Row align={'middle'} className={"px-2"}>
            {caseDetails.case_description.map(cd => (
                <Col lg={6} md={8} key={cd.id}>
                    {cd.description_file &&
                        <img src={cd.description_file} className={'case-description-image cursor-pointer'}
                             onClick={() => {
                                 openImage(cd.description_file)
                             }}/>
                    }
                </Col>
            ))}
        </Row>
        <Row className={"mt-2"} align={'middle'} justify={'end'}>
            <Col xl={6} xs={10}>
            <button type={'button'} className={'btn btn-primary quiz-nav-btn'}
                        onClick={() => changeTab('questions')}>
                    Next
                </button>
            </Col>
        </Row>
    </div>)
}

export function CaseExplanation({caseDetails, changeTab, openImage}) {
    return (
        <div>
            <div dangerouslySetInnerHTML={{__html: caseDetails.case_analysis}}>
            </div>
            <Row className={"mt-2"} align={'middle'} justify={'center'}>
                <Col xl={4} md={8} xs={24}>
                    <img src={caseDetails.case_analysis_files} className={'case-description-image cursor-pointer'}
                         onClick={() => {
                             openImage(caseDetails.case_analysis_files)
                         }}/>
                </Col>
            </Row>
            <Row className={"mt-2"} align={'middle'} justify={'start'}>
                <Col xl={6} xs={10}>
                    <button type={'button'} className={'btn btn-primary quiz-nav-btn'}
                            onClick={() => changeTab('questions')}>
                        Previous
                    </button>
                </Col>
            </Row>
        </div>)
}

export function CaseTest({caseDetails, changeTab, setQuizAttempted, openImage}) {
    const [quiz, setQuiz] = React.useState([]);
    const [currentQuestion, setCurrentQuestion] = React.useState(1)
    const [selectedOptions, setSelectedOptions] = React.useState([]);

    useEffect(() => {
        CasesService.caseQuestions(caseDetails.id).then(response => {
            if (response.success) {
                setQuiz(response.data.quiz);
                setSelectedOptions(quiz.map(q => null));
            }
        }).catch(error => {
            console.log(error);
        })
    }, []);

    const onSelectionChange = (checkedValues) => {
        const updatedOptions = quiz.map(q => q.selected_ans ?? null);
        updatedOptions[currentQuestion - 1] = checkedValues.target.value;
        quiz[currentQuestion - 1].selected_ans = checkedValues.target.value;
        currentQuestionData.selected_ans = checkedValues.target.value;
        setSelectedOptions(updatedOptions);
    };

    const submitResult = () => {
        let attempted = selectedOptions.filter(so => so != null).length;
        let totalQuestions = selectedOptions.length;
        let isCompleted = attempted === totalQuestions ? 1 : 0;
        let score = quiz.filter(q => q.correct_ans === q.selected_ans).length;

        CasesService.submitResult({
            case_id: caseDetails.id,
            score: score,
            total_questions: totalQuestions,
            total_attempted_questions: attempted,
            is_completed: isCompleted,
        }).then(response => {
            if (response.success) {
                setQuizAttempted(true);
                changeTab("explanation");
            }
        }).catch(error => {
            console.log(error);
        })
    }

    let currentQuestionData = quiz[currentQuestion - 1];

    return (<div>
        {
            quiz.length > 0 && <div className={'case-question'}>
                <h2 className={"app-section-title mt-0"}>Q{currentQuestion}. {currentQuestionData.question}</h2>
                <div className={"question-options mb-3"}>
                    <Radio.Group onChange={onSelectionChange} value={currentQuestionData.selected_ans}>
                        <Space direction="vertical">
                            <Radio disabled={currentQuestionData.selected_ans} value="A"
                                   className={selectedOptions[currentQuestion - 1] && "a" === currentQuestionData.correct_ans.toLowerCase() ? 'is-correct' : 'is-wrong'}>{currentQuestionData.option_a}</Radio>
                            {
                                "a" === currentQuestionData.correct_ans.toLowerCase() && selectedOptions[currentQuestion - 1] &&
                                <div>
                                    <p>{currentQuestionData.explanation}</p>
                                    {
                                        currentQuestionData.explanation_file &&
                                        <Row align={'middle'} className={"px-2"}>
                                            <Col lg={6} md={8}>
                                                <img src={currentQuestionData.explanation_file}
                                                     className={'case-description-image'} onClick={() => {
                                                    openImage(currentQuestionData.explanation_file)
                                                }}/>
                                            </Col>
                                        </Row>
                                    }
                                </div>
                            }
                            <Radio disabled={currentQuestionData.selected_ans}
                                   value="B"
                                   className={selectedOptions[currentQuestion - 1] && "b" === currentQuestionData.correct_ans.toLowerCase() ? 'is-correct' : 'is-wrong'}>{currentQuestionData.option_b}</Radio>
                            {
                                "b" === currentQuestionData.correct_ans.toLowerCase() && selectedOptions[currentQuestion - 1] &&
                                <div>
                                    <p>{currentQuestionData.explanation}</p>
                                    {
                                        currentQuestionData.explanation_file &&
                                        <Row align={'middle'} className={"px-2"}>
                                            <Col lg={6} md={8}>
                                                <img src={currentQuestionData.explanation_file}
                                                     className={'case-description-image'} onClick={() => {
                                                    openImage(currentQuestionData.explanation_file)
                                                }}/>
                                            </Col>
                                        </Row>
                                    }
                                </div>
                            }
                            <Radio disabled={currentQuestionData.selected_ans}
                                   value="C"
                                   className={selectedOptions[currentQuestion - 1] && "c" === currentQuestionData.correct_ans.toLowerCase() ? 'is-correct' : 'is-wrong'}>{currentQuestionData.option_c}</Radio>
                            {
                                "c" === currentQuestionData.correct_ans.toLowerCase() && selectedOptions[currentQuestion - 1] &&
                                <div>
                                    <p>{currentQuestionData.explanation}</p>
                                    {
                                        currentQuestionData.explanation_file &&
                                        <Row align={'middle'} className={"px-2"}>
                                            <Col lg={6} md={8}>
                                                <img src={currentQuestionData.explanation_file}
                                                     className={'case-description-image'} onClick={() => {
                                                    openImage(currentQuestionData.explanation_file)
                                                }}/>
                                            </Col>
                                        </Row>
                                    }
                                </div>
                            }
                            <Radio disabled={currentQuestionData.selected_ans}
                                   value="D"
                                   className={selectedOptions[currentQuestion - 1] && "d" === currentQuestionData.correct_ans.toLowerCase() ? 'is-correct' : 'is-wrong'}>{currentQuestionData.option_d}</Radio>
                            {
                                "d" === currentQuestionData.correct_ans.toLowerCase() && selectedOptions[currentQuestion - 1] &&
                                <div>
                                    <p>{currentQuestionData.explanation}</p>
                                    {
                                        currentQuestionData.explanation_file &&
                                        <Row align={'middle'} className={"px-2"}>
                                            <Col lg={6} md={8}>
                                                <img src={currentQuestionData.explanation_file}
                                                     className={'case-description-image'} onClick={() => {
                                                    openImage(currentQuestionData.explanation_file)
                                                }}/>
                                            </Col>
                                        </Row>
                                    }
                                </div>
                            }
                        </Space>
                    </Radio.Group>
                </div>

                <Row className={"mt-2"} align={'middle'} justify={'space-between'}>
                    <Col xl={6} xs={10}>
                        {
                            currentQuestion === 1 &&
                            <button type={'button'} className={'btn btn-primary quiz-nav-btn'}
                                    onClick={() => changeTab('description')}>
                                Previous
                            </button>
                        }
                        {
                            currentQuestion > 1 &&
                            <button type={'button'} className={'btn btn-primary quiz-nav-btn'}
                                    onClick={() => setCurrentQuestion(currentQuestion - 1)}>
                                Previous
                            </button>
                        }
                    </Col>
                    <Col xl={6} xs={10}>
                        {
                            currentQuestion < quiz.length &&
                            <button type={'button'} className={'btn btn-primary quiz-nav-btn'}
                                    onClick={() => setCurrentQuestion(currentQuestion + 1)}>
                                Next
                            </button>
                        }
                        {
                            currentQuestion >= quiz.length &&
                            <button type={'button'} className={'btn btn-primary quiz-nav-btn'}
                                    onClick={() => submitResult()}>
                                Next
                            </button>
                        }
                    </Col>
                </Row>
            </div>
        }
    </div>)
}