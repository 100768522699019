import './dashboard.css';
import UsersService from "../../services/users.service";
import {Col, Row} from "antd";
import React from "react";
import {Link} from "react-router-dom";
import Chart from "react-apexcharts";
import {PlayCircleFilled} from "@ant-design/icons";
import {LearningCards} from "../../components/LearningCards/LearningCards";

export function Dashboard() {
    let series =  [{
        name: 'Performance',
        data: [2.5, 3, 4, 1]
    }];

    let chart = {
        colors:["#6739B7"],
        fill: {
            type: 'gradient',
            gradient: {
                type: "vertical",
                opacityFrom: 0.6,
                opacityTo: 0,
                stops: [0, 80],
            }
        },
        chart: {
            toolbar:{
                show: false,
            },
            height: 350,
            type: 'area'
        },
        dataLabels: {
            enabled: true
        },
        stroke: {
            curve: "smooth"
        },
        grid:{
            show:true,
        },
        legend:{
            horizontalAlign: "right"
        },
        xaxis: {
            labels: {
                show: true
            },
            axisTicks: {
                show: false
            },
            tooltip: {
                enabled: false
            },
            type: 'numeric',
            categories: [1,2,3,4]
        },
    };

    const user = UsersService.currentUser();

    return (
        <div>
            <Row>
                <Col xl={18} lg={24} md={24} className={"pr-3"}>
                    <div className={"welcome-container"}>
                        <Row align={'middle'} justify={'space-between'}>
                            <Col>
                                <h2>Welcome back, Dr. {user.first_name}
                                    <img src={"/images/waving-emoji.png"} width={32} className={'ml-2'} alt={'hello'}/></h2>
                                <p>You’ve learned <b>0%</b> of your goal this week!</p>
                                <p>Keep it up and improve your progress.</p>
                            </Col>
                            <Col>
                                <img src={"/images/human-space.png" } alt={"human-icon"} className={'md-none'}/>
                            </Col>
                        </Row>
                    </div>
                    <div className={'mt-3 px-4'}>
                        <h2 className={"app-section-title"}>Continue Learning
                            <Link to={'/cases/9'} className={'btn btn-primary ml-3 p-2'}><PlayCircleFilled /></Link>
                        </h2>

                        <LearningCards />

                        <div className={"mt-5 dashboard-chart"} >
                            <Chart options={chart} series={series} type="area" height={300} />
                        </div>
                    </div>
                </Col>
                <Col xl={6} lg={24} md={24} className={'px-2'}>
                    <h2 className={"app-section-title"}>Progress Report</h2>
                </Col>
            </Row>
        </div>
    );
}