import React from 'react';
import {Route, Routes} from 'react-router-dom';
import {CaseDetails} from "../../pages/cases/details/CaseDetails";
import {Cases} from "../../pages/cases/Cases";
const {Dashboard} = require("../../pages/dashboard/Dashboard");

const PageContent = () => {
    return (
        <Routes>
            <Route path="/dashboard" element={<Dashboard/>} />
            <Route path="/cases" element={<Cases/>} />
            <Route path="/cases/:id" element={<CaseDetails/>} />
        </Routes>
    );
}

export default PageContent;