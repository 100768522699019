import React, {useEffect} from "react";
import CasesService from "../../services/cases.service";
import {Button, Col, Collapse, Progress, Row} from "antd";
import {LearningCards} from "../../components/LearningCards/LearningCards";
import "./cases.css";
import CollapsePanel from "antd/es/collapse/CollapsePanel";
import Icon from "antd/es/icon";
import {CheckCircleOutlined} from "@ant-design/icons";

export function Cases() {
    const [allPlans, setAllPlans] = React.useState([]);
    const [selectedPlan, setSelectedPlan] = React.useState();

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const [allPlansResponse] = await Promise.all([
            CasesService.allPlans(),
        ]);

        setAllPlans(allPlansResponse.data);
        allPlansResponse.data.forEach(plan => {
            CasesService.master(plan.id).then(response => {
                if(response.success){
                    setAllPlans(allPlans =>
                        allPlans.map(p =>
                            p.id === plan.id
                                ? {
                                    ...p,
                                    is_purchased: response.data.length > 0,
                                    subjects: response.data,
                                    loaded: true
                                }
                                : p
                        )
                    );
                }
                return [];
            }).catch(response => {
                return [];
            });

        })
    }

    return (<div>
        <Row>
            <Col xl={18} lg={24} md={24} className={"pr-3"}>
                <div className={'mt-3 px-4'}>
                    <h2 className={"app-section-title"}>Continue Learning</h2>
                    <LearningCards/>
                    <div className={"mt-5"}>
                        {allPlans.map((plan, i) => {
                            return <PlanDetails plan={plan} key={plan.id} selectedPlan={selectedPlan} setSelctedPlan={setSelectedPlan}/>
                        })}
                    </div>
                </div>
            </Col>
            <Col xl={6} lg={24} md={24} className={'px-2'}>
                <h2 className={"app-section-title"}>Progress Report</h2>
            </Col>
        </Row>
    </div>);
}

export function PlanDetails({plan, selectedPlan, setSelctedPlan}){
    return (<Row className={"case-container mt-3 cursor-pointer"} gutter={10} onClick={() => setSelctedPlan(plan.id)}>
        {/*<Col lg={6} span={24}>*/}
        {/*    <img src={caseData.case_analysis_files} className={"case-list-image"}/>*/}
        {/*</Col>*/}
        <Col span={18} md={20}>
            <h2 className={"mt-0 mb-1"}>{plan.subscription_name}</h2>
            {/*<Progress percent={75} showInfo={false} status={"success"}/>*/}
            <p className={"case-video-text mb-0"}>Text1. Text2</p>

            {/*<div className={"case-sub-text"}>*/}
            {/*    <p className={"case-completed-text mr-2"}>37/50 Cases Completed</p> | <p*/}
            {/*    className={"ml-2 case-strike-rate"}>73% Strike Rate</p>*/}
            {/*</div>*/}

        </Col>
        <Col span={6} md={4} className={"text-end"}>
            {!plan.is_purchased && plan.loaded &&
                <Button type="primary" className={'btn btn-outline-primary'}
                        htmlType="button">
                    Buy
                </Button>
            }
        </Col>
        <Col span={24}>
            {plan.is_purchased && selectedPlan == plan.id &&
                <Collapse className={"subject-accordion mt-3"} accordion={true}>
                    {plan.subjects.map(subject => {
                        return (<CollapsePanel header={<div className={"subject-header"}>
                            <Row>
                                <Col span={21}>
                                    <h3 className={"m-0"}>{subject.subject.subject_name}</h3>
                                </Col>
                                <Col span={3} className={"d-flex justify-content-end align-items-center"}>
                                    {subject.is_completed &&
                                        <CheckCircleOutlined className={"completed-icon"}/>
                                    }
                                    <h5 className={"ml-3 my-0"}>{Math.round(subject.completed_topics * 100 / subject.total_topics)}%</h5>
                                </Col>
                            </Row>
                            <Progress percent={subject.completed_topics * 100 / subject.total_topics} showInfo={false} status={"success"}/>
                        </div> } key={subject.subject.id}>
                            <Collapse accordion={true}>
                                {subject.topics.map(topic => {
                                    return (<CollapsePanel header={<div className={"subject-header"}>
                                        <Row>
                                            <Col span={21}>
                                                <h3 className={"m-0"}>{topic.topic.topic_name}</h3>
                                            </Col>
                                            <Col span={3} className={"d-flex justify-content-end align-items-center"}>
                                                { topic.is_completed &&
                                                    <CheckCircleOutlined className={"completed-icon"} />
                                                }
                                                <h5 className={"ml-3 my-0"}>{Math.round(topic.completed_cases * 100 / topic.total_cases)}%</h5>
                                            </Col>
                                        </Row>
                                        <Progress percent={topic.completed_cases * 100 / topic.total_cases}
                                                  showInfo={false} status={"success"}/>
                                    </div>} key={topic.topic.id}>
                                        {topic.cases.map(caseData => {
                                            return (<h4 key={caseData.id} className={"my-2"}>
                                                <a href={"/cases/" + caseData.id}>{caseData.case_name}</a>
                                            </h4>)
                                        })}
                                    </CollapsePanel>)
                                })}
                            </Collapse>
                        </CollapsePanel>)

                    })}
                </Collapse>
            }
        </Col>
    </Row>)
}