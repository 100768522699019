import {Card, Col, Row} from "antd";
import React from "react";
import "./learning-cards.css";

export function LearningCards(){
    return (<Row gutter={10}>
        <Col xl={7} lg={8} md={24}>
            <Card bordered={false} className={"learning-card bg-purple"}>
                <img src={'/images/case-icon.png'} className={'learning-card-img'} alt={'cases'}/>
                <h3 className={"mb-0"}>Cases</h3>
                <p className={"font-light"}>Topic A</p>
                <div className={"learning-statistics"}>
                    <div className={"d-flex"}>
                        <img src={"/images/book-icon.png"} alt={"book"}/>
                        <h5 className={"ml-2"}>24</h5>
                    </div>
                    <div className={"separator"}></div>
                    <div className={"d-flex"}>
                        <img src={"/images/clipboard-icon.png"} alt={"book"}/>
                        <h5 className={"ml-2"}>8</h5>
                    </div>
                    <div className={"separator"}></div>
                    <div className={"d-flex"}>
                        <img src={"/images/users-icon.png"} alt={"book"}/>
                        <h5 className={"ml-2"}>99</h5>
                    </div>
                </div>
            </Card>
        </Col>
        <Col xl={7} lg={8} md={24}>
            <Card bordered={false} className={"learning-card bg-orange h-100"}>
                <img src={'/images/case-icon.png'} className={'learning-card-img'} alt={'cases'}/>
                <h3 className={""}>Branding Design</h3>
                <div className={"learning-statistics"}>
                    <div className={"d-flex"}>
                        <img src={"/images/book-icon.png"} alt={"book"}/>
                        <h5 className={"ml-2"}>24</h5>
                    </div>
                    <div className={"separator"}></div>
                    <div className={"d-flex"}>
                        <img src={"/images/clipboard-icon.png"} alt={"book"}/>
                        <h5 className={"ml-2"}>8</h5>
                    </div>
                    <div className={"separator"}></div>
                    <div className={"d-flex"}>
                        <img src={"/images/users-icon.png"} alt={"book"}/>
                        <h5 className={"ml-2"}>99</h5>
                    </div>
                </div>
            </Card>
        </Col>
        <Col xl={7} lg={8} md={24}>
            <Card bordered={false} className={"learning-card bg-green h-100"}>
                <img src={'/images/case-icon.png'} className={'learning-card-img'} alt={'cases'}/>
                <h3 className={""}>Motion Design</h3>
                <div className={"learning-statistics"}>
                    <div className={"d-flex"}>
                        <img src={"/images/book-icon.png"} alt={"book"}/>
                        <h5 className={"ml-2"}>24</h5>
                    </div>
                    <div className={"separator"}></div>
                    <div className={"d-flex"}>
                        <img src={"/images/clipboard-icon.png"} alt={"book"}/>
                        <h5 className={"ml-2"}>8</h5>
                    </div>
                    <div className={"separator"}></div>
                    <div className={"d-flex"}>
                        <img src={"/images/users-icon.png"} alt={"book"}/>
                        <h5 className={"ml-2"}>99</h5>
                    </div>
                </div>
            </Card>
        </Col>
    </Row>)
}